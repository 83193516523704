import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import {uuidv4} from "../../components/MessageInput/";
import logFunc from "../../hooks/logFunc";

const reducer = (state, action) => {
	if (action.type === "LOAD_CONTACTS") {
		const contacts = action.payload;
		const newContacts = [];

		contacts.forEach(contact => {
			const contactIndex = state.findIndex(c => c.id === contact.id);
			if (contactIndex !== -1) {
				state[contactIndex] = contact;
			} else {
				newContacts.push(contact);
			}
		});

		return [...state, ...newContacts];
	}

	if (action.type === "UPDATE_CONTACTS") {
		const contact = action.payload;
		const contactIndex = state.findIndex(c => c.id === contact.id);

		if (contactIndex !== -1) {
			state[contactIndex] = contact;
			return [...state];
		} else {
			return [contact, ...state];
		}
	}

	if (action.type === "DELETE_CONTACT") {
		const contactId = action.payload;

		const contactIndex = state.findIndex(c => c.id === contactId);
		if (contactIndex !== -1) {
			state.splice(contactIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		padding: theme.spacing(1),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
}));

const Contacts = () => {
	const classes = useStyles();
	const history = useHistory();

	const { user } = useContext(AuthContext);

	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [searchParam, setSearchParam] = useState("");
	const [contacts, dispatch] = useReducer(reducer, []);
	const [selectedContactId, setSelectedContactId] = useState(null);
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const [deletingContact, setDeletingContact] = useState(null);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [whatsappNumberList, setWhatsappNumberList] = useState([]);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [contactSelected, setContactSelected] = useState(null);

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [searchParam]);

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("/contacts/", {
						params: { searchParam, pageNumber },
					});
					dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
					setHasMore(data.hasMore);
					setLoading(false);
				} catch (err) {
					toastError(err);
				}
			};
			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, pageNumber]);

	useEffect(() => {
		const socket = openSocket();

		socket.on("contact", data => {
			if (data.action === "update" || data.action === "create") {
				dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleSearch = event => {
		setSearchParam(event.target.value.toLowerCase());
	};

	const handleOpenContactModal = () => {
		setSelectedContactId(null);
		setContactModalOpen(true);
	};

	const handleCloseContactModal = () => {
		setSelectedContactId(null);
		setContactModalOpen(false);
	};
	//DESATEL
	const handleSaveTicket = async (contactId, whatsappId, tipoCall) => {
		setLoading(true);
		let contacto = 0;
		try {
			// const { data: ticket } = await api.post("/tickets", {
				// contactId: contactId,
				// userId: user?.id,
				// status: "open",
			// });
			//console.log("handleSaveTicket userId: ",user?.id);
			//console.log("handleSaveTicket contactSelected: ",contactSelected);
			//console.log("handleSaveTicket contactId: ",contactId);
			if (tipoCall === 1) {
				contacto = contactSelected;
			} else {
				contacto = contactId;
			}
			const { data: ticket } = await api.post("/ticketswid", {
				contactId: contacto,
				userId: user?.id,
				status: "open",
				whatsappId: whatsappId,
			});
			//
			let idUuid2 = uuidv4();
					//console.log("solo tiene 1 conversacion");
					logFunc(1,"pages contacts index get tickets ticket.id ",ticket.id);
					let { data } = await api.get("/tickets/" + ticket.id);
					//console.log("data: ",JSON.stringify(data));
					let message3 = {
						read: 1,
						idUuid: idUuid2,
						fromMe: true,
						mediaUrl: "",
						body: `-> El usuario ${data.user.name} inicio el chat con WhatsApp: ${data.whatsapp.name}` 
						};
						try {
							await api.post(`/messagescomentuid/${ticket.id}`, message3);
						} catch (err) {
							toastError(err);
						}
			logFunc(1,"pages contacts index history.push ini ticket.id ",ticket.id);
			history.push(`/tickets/${ticket.id}`);
			logFunc(1,"pages contacts index history.push fin ticket.id ",ticket.id);
		} catch (err) {
			setWhatsappNumberList([])
			setMenuAnchor(null)
			toastError(err);
		}
		setLoading(false);
	};
	
	const onClickWhatsapp = async (currentTarget,contactId)  =>{
		//console.log("onClickWhatsapp userId: ",user?.id);
		//console.log("onClickWhatsapp contactId: ",contactId);
		setContactSelected(contactId);
		//console.log("Event: ", currentTarget)
		if (!contactId) return;
		const { data } = await api.get("/whatsappgetall/");
		if(data.length === 1){
			handleSaveTicket(contactId, data[0].id,2)
			return
		}
		
		setWhatsappNumberList(data)
		setMenuAnchor(currentTarget)
		
		//console.log(JSON.stringify(data));
	
	
	}
	
	const onMenuClose = () => {
		setWhatsappNumberList([])
		setMenuAnchor(null)
	}

	const hadleEditContact = contactId => {
		setSelectedContactId(contactId);
		setContactModalOpen(true);
	};

	const handleDeleteContact = async contactId => {
		try {
			await api.delete(`/contacts/${contactId}`);
			toast.success(i18n.t("contacts.toasts.deleted"));
		} catch (err) {
			toastError(err);
		}
		setDeletingContact(null);
		setSearchParam("");
		setPageNumber(1);
	};

	const handleimportContact = async () => {
		try {
			await api.post("/contacts/import");
			//history.go(0);
			history.push("/");
			history.push("/contacts");
		} catch (err) {
			toastError(err);
		}
	};

	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = e => {
		if (!hasMore || loading) return;
		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			loadMore();
		}
	};

	return (
		<MainContainer className={classes.mainContainer}>
			<ContactModal
				open={contactModalOpen}
				onClose={handleCloseContactModal}
				aria-labelledby="form-dialog-title"
				contactId={selectedContactId}
			></ContactModal>
			<ConfirmationModal
				title={
					deletingContact
						? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${
								deletingContact.name
						  }?`
						: `${i18n.t("contacts.confirmationModal.importTitlte")}`
				}
				open={confirmOpen}
				onClose={setConfirmOpen}
				onConfirm={e =>
					deletingContact
						? handleDeleteContact(deletingContact.id)
						: handleimportContact()
				}
			>
				{deletingContact
					? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
					: `${i18n.t("contacts.confirmationModal.importMessage")}`}
			</ConfirmationModal>
			<MainHeader>
				<Title><p>. </p>{i18n.t("contacts.title")}</Title>
				<MainHeaderButtonsWrapper>
					<TextField
						placeholder={i18n.t("contacts.searchPlaceholder")}
						type="search"
						value={searchParam}
						onChange={handleSearch}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon style={{ color: "gray" }} />
								</InputAdornment>
							),
						}}
					/>
					<Button
						variant="contained"
						color="primary"
						onClick={e => setConfirmOpen(true)}
					>
						{i18n.t("contacts.buttons.import")}
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleOpenContactModal}
					>
						{i18n.t("contacts.buttons.add")}
					</Button>
				</MainHeaderButtonsWrapper>
			</MainHeader>
			<Paper
				className={classes.mainPaper}
				variant="outlined"
				onScroll={handleScroll}
			>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox" />
							<TableCell>{i18n.t("contacts.table.name")}</TableCell>
							<TableCell align="center">
								{i18n.t("contacts.table.whatsapp")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("contacts.table.email")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("contacts.table.actions")}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<>
							{contacts.map(contact => (
								<TableRow key={contact.id}>
									<TableCell style={{ paddingRight: 0 }}>
										{<Avatar src={contact.profilePicUrl} 
											imgProps={{
												onError: () => {
														contact.profilePicUrl = ".";
														api.put(`/contactsupdate/${contact.id}`, contact);
													},
												}}
										/>}
									</TableCell>
									<TableCell>{contact.name}</TableCell>
									<TableCell align="center">{contact.number}</TableCell>
									<TableCell align="center">{contact.email}</TableCell>
									<TableCell align="center">
										<IconButton
											size="small"
											onClick={({currentTarget}) => onClickWhatsapp(currentTarget,contact.id)}
										>
											<WhatsAppIcon />
										</IconButton>
										{!!whatsappNumberList.length && 
										<Menu
										id="simple-menu"
										anchorEl={menuAnchor}
										keepMounted
										open={Boolean(menuAnchor)}
										onClose={onMenuClose}
									  >
											{whatsappNumberList.map( el => <MenuItem key={el.id} onClick={() => handleSaveTicket(contact.id, el.id,1)}>{el.name} - {el.greetingMessage}</MenuItem>)}
										</Menu>}
										<IconButton
											size="small"
											onClick={() => hadleEditContact(contact.id)}
										>
											<EditIcon />
										</IconButton>
										<Can
											role={user.profile}
											perform="contacts-page:deleteContact"
											yes={() => (
												<IconButton
													size="small"
													onClick={e => {
														setConfirmOpen(true);
														setDeletingContact(contact);
													}}
												>
													<DeleteOutlineIcon />
												</IconButton>
											)}
										/>
									</TableCell>
								</TableRow>
							))}
							{loading && <TableRowSkeleton avatar columns={3} />}
						</>
					</TableBody>
				</Table>
			</Paper>
		</MainContainer>
	);
};

export default Contacts;
